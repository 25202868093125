import { getIconSrc } from "@/lib/icon-utils";
import { cn } from "@/lib/utils";

interface Props {
  symbol: string;
  classNames?: string;
}

export const TokenIcon: React.FC<Props> = ({ symbol, classNames }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        src={getIconSrc(symbol)}
        alt={`${symbol} icon`}
        className={cn("w-8 h-8", classNames)}
      />
    </div>
  );
};
