import { Link } from "react-router-dom";
import { ResetIcon } from "@radix-ui/react-icons";

export const TermsOfUse: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen gap-y-6">
      <div className="flex pt-16 pb-8 m-auto items-center">
        <div className="text-2xl text-secondary-foreground font-extrabold">
          Sedge
        </div>
        <div className="ml-2 text-2xl text-secondary-foreground/80 font-medium">
          Terms of Use
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4 text-sm">
        <div className="italic">Last updated: February 2025</div>
        <div className="italic">
          Do not access this site where such access is prohibited by applicable
          law. Please carefully read these terms of use before using the site.
          These terms apply to any person or entity accessing the site and by
          using the site you agree to be bound by them. The terms of use contain
          a mandatory individual arbitration and class action/jury trial waiver
          provision that requires the use of arbitration on an individual basis
          to resolve disputes, rather than jury trials or class actions. If you
          do not want to be bound by these terms of use, you should not access
          the site. By using the site in any capacity, you agree that you have
          read, understood, and agree to be subject to these terms of use.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          1.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Overview
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          This Sedge Terms of Use document (“Terms” or “agreement”) (“Sedge ”,
          “we” and “us” refers to the New Leaf Digital Limited) covers the
          website, Sedge Protocol user-interface and free application
          (collectively “the Site”) we own and administer, at times in
          conjunction with others, which provides the ability to access the
          decentralized Sedge Protocol. Additionally, you can access the Sedge
          Protocol through third-party web or mobile interfaces. These Terms
          apply to you (“You” or “you”) as a user of our Site including all the
          products, services, tools and information, without limitation, made
          available on the Site.
        </div>
        <div>
          To avoid any confusion, you agree a) you retain full control, at all
          times, over your cryptocurrency assets, b) there are no intermediaries
          involved when you interact with the Sedge Protocol and c) the online
          interface (UI) is a mere graphical interface for you to interact with
          the Sedge protocol, which can be accessed through other means,
          including other interfaces.
        </div>
        <div>
          You must be able to form a legally binding contract online either as
          an individual or on behalf of a legal entity. You represent that, if
          you are agreeing to these Terms on behalf of a legal entity, you have
          the legal authority to bind that entity to these Terms and you are not
          indirectly or directly included on any sanctions list and at least 18
          years old or the age of majority where you reside, (whichever is
          older) can form a legally binding contract online, and have the full,
          right, power and authority to enter into and to comply with the
          obligations under these Terms.
        </div>
        <div>
          You are advised to periodically review these Terms so you understand
          any changes to the Terms. Sedge in its sole discretion, reserves the
          right to make changes to our Terms. Changes are binding on users of
          the Site and will take effect immediately upon posting. As a user, you
          agree to be bound by any changes, variations, or modifications to our
          Terms and your continued use of the Site shall constitute acceptance
          of any such changes, revisions, variations, or modifications. When we
          make changes, we will make the updated Terms available on the
          interface and update the “Last Updated” date at the beginning of the
          Terms accordingly.
        </div>
        <div>
          You accept such changes, by continuing to use the Site and by doing so
          you agree that we have provided you with sufficient notice of such
          change. You are entering into a binding Agreement. Any failure by us
          to exercise any rights or provisions of the Agreement shall not
          constitute a waiver of such right or provision.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          2.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Site
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          As part of the Site, Sedge provides access to a decentralized finance
          application (“Application” or “Sedge Protocol app”) on the Ethereum
          blockchain, that allows swappers or liquidity providers of Ethereum
          assets (“Cryptocurrency Assets”) to transact using smart contracts
          (“Smart Contracts”). Use of the Sedge Protocol may require that you
          pay a fee, such as gas charges on the Ethereum network to perform a
          transaction. You acknowledge and agree that Sedge has no control over
          any activities, transactions, the method of payment of any
          transactions, or any actual processing of payments of transactions.
          You must ensure that you have a sufficient balance to complete any
          transaction on the Sedge Protocol before initiating such transaction.
          You should not take or refrain from taking any action based on any
          information contained on the Site or any other available information
          at any time. Before you make any legal, technical, or financial
          decisions involving the Services, you should seek independent
          professional advice from a licensed and qualified individual in the
          area for which such advice would be appropriate.
        </div>
        <div>
          You acknowledge and agree that Sedge has no control over any
          transactions conducted through the Sedge Protocol, the method of
          payment of any transactions or any actual payments of transactions
          including use of any third-party services such as Metamask, or other
          wallet services. Likewise, you must ensure that you have a sufficient
          balance of the applicable cryptocurrency tokens stored at your Sedge
          Protocol-compatible wallet address (“Cryptocurrency Wallet”) to
          complete any transaction on the Sedge Protocol or the Ethereum network
          before initiating such transaction.
        </div>
        <div>
          You acknowledge and accept the material potential risks associated
          with using the smart contracts that can be accessed via the Sedge user
          interface including, without limitation, the smart contracts for Sedge
          Pools. Before using these features or Pools, please confirm you
          understand the functionality and risks.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          3.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Access / Disclaimer of Warranties
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          ACCESS TO THIS SITE AND THE PRODUCTS HEREIN ARE PROVIDED ON AN ``AS
          IS`` AND ``AS AVAILABLE`` BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. NO WARRANTY IS PROVIDED THAT THE SITE OR ANY PRODUCT
          WILL BE FREE FROM DEFECTS OR VIRUSES OR THAT OPERATION OF THE PRODUCT
          WILL BE UNINTERRUPTED. YOUR USE OF THE SITE AND ANY PRODUCT AND ANY
          MATERIAL OR SERVICES OBTAINED OR ACCESSED VIA THE SITE IS AT YOUR OWN
          DISCRETION AND RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE
          RESULTING FROM THEIR USE. SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY
          NOT APPLY TO YOU. TRANSACTIONS THAT ARE RECORDED VIA THE SITE MUST BE
          TREATED AS PERMANENT AND CANNOT BE UNDONE BY US OR BY ANYONE.
        </div>
        <div>
          We do not guarantee or promise that the Site, or any content on it,
          will always be available, functional, usable or uninterrupted. From
          time to time, access may be interrupted, suspended or restricted,
          including because of a fault, error or unforeseen circumstances or
          because we are carrying out planned maintenance or changes. You
          acknowledge and agree that you will access and use the site at your
          own risk. By using the Site, you will be solely responsible for
          conducting your own due diligence into the risks of a transaction.
        </div>
        <div>
          We reserve the right to limit the availability of the site to any
          person, geographic area or jurisdiction in our sole discretion and/or
          to terminate your access to and use of the site, at any time and in
          our sole discretion. We may suspend or disable your access to the Site
          for any reason and in our sole discretion, including for any
          intentional or unintentional breaches of these Terms. We may remove or
          amend the content of the Site at any time. Some of the content may be
          out of date at any given time and we are under no obligation to update
          or revise it. We do not promise or guarantee that the Site, or any
          content on it, will be free from errors or omissions.
        </div>
        <div>
          We will not be liable to you for any issue, loss or damage you may or
          have suffered as a result of the Site being unavailable at any time
          for any reason. You will comply with all applicable domestic and
          international laws, statutes, ordinances, rules and regulations
          applicable to your use of the site (“Applicable Laws”). Likewise, we
          are not liable for any third-party services and are not responsible
          for the content or services of these parties.
        </div>
        <div>
          <div>
            As a condition to accessing or using the Site, you agree and
            represent that you will:
          </div>
          <div className="w-[95%] m-auto pt-2 text-sm">
            <ul className="flex flex-col list-disc list-inside gap-2">
              <li>
                Only use the Services and the Site for lawful purposes and in
                adherence with these Terms;
              </li>
              <li>
                Ensure that all information that you provide on the Site is
                current, complete, and accurate; and
              </li>
              <li>
                Maintain the security, privacy and confidentiality of access to
                your cryptocurrency wallet address.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            As a condition to accessing or using the Site or the Services, you
            will not:
          </div>
          <div className="w-[95%] m-auto pt-2 text-sm">
            <ul className="flex flex-col list-disc list-inside gap-2">
              <li>
                Violate any Applicable Law, including, without limitation, any
                relevant and applicable anti-money laundering and anti-terrorist
                financing and sanctions laws and any relevant and applicable
                privacy, secrecy and data protection laws.
              </li>
              <li>
                Use the Site for any purpose or conduct that is directly or
                indirectly unlawful;
              </li>
              <li>
                Export, reexport, or transfer, directly or indirectly, any Sedge
                technology in violation of applicable export laws or
                regulations;
              </li>
              <li>
                Infringe on or misappropriate any contract, intellectual
                property or other third-party right, or commit a tort while
                using the Site;
              </li>
              <li>
                Misrepresent, with omission or otherwise, the truthfulness,
                sourcing or reliability of any content on the Site;
              </li>
              <li>
                Use the Site in any manner that could interfere with, disrupt,
                negatively affect, redirect or inhibit other users from fully
                enjoying the Site or the Sedge Protocol, or that could damage,
                disable, overburden, or impair the functioning of the Site or
                the Sedge Protocol in any manner;
              </li>
              <li>
                Attempt to circumvent or disable any content filtering
                techniques or security measures that Sedge employs on the Site,
                or attempt to access any service or area of the Site that you
                are not authorized to access;
              </li>
              <li>
                Use any robot, spider, crawler, scraper, or other automated
                means or interface not provided by us, to access the Site to
                extract data;
              </li>
              <li>
                Introduce or use any malware, virus, Trojan horse, worm, logic
                bomb, drop-dead device, backdoor, shutdown mechanism or other
                harmful material into the Site;
              </li>
              <li>
                Post content or communications on the Site that are, in our sole
                discretion, libelous, defamatory, profane, obscene,
                pornographic, sexually explicit, indecent, lewd, vulgar,
                suggestive, harassing, hateful, threatening, offensive,
                discriminatory, bigoted, abusive, inflammatory, fraudulent,
                deceptive or otherwise objectionable;
              </li>
              <li>
                To the extent applicable, post content on the Site containing
                unsolicited promotions, commercial messages or any chain
                messages or user content designed to deceive, induce or trick
                the user of the Site; or
              </li>
              <li>
                Encourage or induce any third party to engage in any of the
                activities prohibited under these Terms.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          3(a).
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          You acknowledge that the Site and your use of the Site present certain
          risks, including without limitation the following risks:
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div className="w-[95%] m-auto pt-2 text-sm">
          <ul className="flex flex-col list-disc list-inside gap-2">
            <li>
              Losses while digital assets are being supplied to the Sedge
              Protocol and losses due to the fluctuation of prices of tokens in
              a swapping pair or liquidity pool. Prices of digital currencies,
              tokens and/or other digital assets fluctuate day by day or even
              minute by minute. The value of your available balance could surge
              or drop suddenly. Please note that there is a possibility that the
              price of tokens could decrease to zero. Prices of tokens are prone
              to significant fluctuations, for example, due to announced
              proposed legislative acts, governmental restrictions, news related
              to cyber crimes or other factors causing potentially excessive
              market enthusiasm, disproportionate loss in confidence, or
              manipulation by others in the market.
            </li>
            <li>
              Risks associated with accessing the Sedge Protocol through third
              party web or mobile interfaces. You are responsible for doing your
              own diligence on those interfaces to understand and accept the
              risks that use entails. You are also responsible for doing your
              own diligence on those interfaces to understand and accept any
              fees that those interfaces may charge.
            </li>
            <li>
              Risks associated with any Smart Contracts with which you interact.
            </li>
            <li>
              Although Sedge does not have access to your assets, you are
              reminded and acknowledge that at any time, your access to your
              Cryptocurrency Assets through third party wallet services,
              unrelated to Sedge or the Sedge.Fi website, may be suspended or
              terminated or there may be a delay in your access or use of your
              Cryptocurrency Assets, which may result in the Cryptocurrency
              Assets diminishing in value or you being unable to complete a
              Smart Contract.
            </li>
            <li>
              You are reminded of the inherent risks with digital assets and
              decentralized finance including the fact that tokens are not legal
              tender and are not backed by any government. Unlike fiat
              currencies, which are regulated and backed by local governments
              and central banks, tokens are based only on technology and user
              consensus, which means that in cases of manipulations or market
              panic, central governments will not take any corrective actions or
              measures to achieve stability, maintain liquidity or protect their
              value. There is a possibility that certain transactions cannot be
              settled or may be difficult to settle, or can be completed only at
              significantly adverse prices depending on the market situation
              and/or market volume. Transactions may be irreversible, and,
              accordingly, potential losses due to fraudulent or accidental
              transactions are not recoverable. Some blockchain transactions are
              deemed to be completed when recorded on a public ledger, which is
              not necessarily the date or time when you or another party
              initiated the transaction.
            </li>
            <li>
              The regulatory frameworks applicable to blockchain transactions in
              connection with tokens are still developing and evolving. It is
              possible that your transactions or funds are, or may be in the
              future, subject to various reporting, tax or other liabilities and
              obligations. Legislative and regulatory changes or actions at the
              country or international level may materially and adversely affect
              the use, transfer, exchange, and value of your tokens.
            </li>
            <li>
              The site and/or application may be wholly or partially suspended
              or terminated for any or no reason, which may limit your access to
              your Cryptocurrency Assets.
            </li>
            <li>
              You are solely responsible for understanding and complying with
              any and all Applicable Laws in connection with your acceptance of
              these Terms and your use of any part of the Site, including but
              not limited to those related to taxes as well as reporting and
              disclosure obligations.
            </li>
            <li>
              This list of risk factors is non-exhaustive, and other risks,
              arising either now or in the future, could additionally be
              relevant and applicable to you in making an informed judgment to
              accept, or continue to accept, these Terms and/or use, or continue
              to use the Site.
            </li>
          </ul>
        </div>

        <div className="font-bold">
          Violating our rules may result in our intervention.
        </div>

        <div>
          You agree and acknowledge that if you use the Site and its Services to
          engage in conduct prohibited by applicable law, we reserve the right
          to completely or partially restrict or revoke your access to the
          Services at our sole discretion. We reserve the right to investigate
          violations and prosecute any suspected breaches of this Agreement,
          including the Terms. Any information may be disclosed to satisfy any
          new regulation, law, government request, or legal process.
        </div>

        <div>
          <div>Accordingly, you expressly agree that:</div>
          <div className="w-[95%] m-auto pt-2 text-sm">
            <ol className="flex flex-col list-decimal list-inside gap-2">
              <li>
                you assume all risk in connection with the specific risks
                identified above in 3(a);
              </li>
              <li>
                you assume all risk in connection with your access to and use of
                the Site, the Application and the Smart Contracts;
              </li>
              <li>
                that you expressly waive and release Sedge from any and all
                liability, claims, causes of action, responsibility or damages
                arising from or in any way related to your use of the Site, the
                Application or the Smart Contracts.
              </li>
              <li>
                upgrades and modifications to the protocol are managed in a
                community-driven way. No developer or entity involved in
                creating the Sedge Protocol will be liable for any claims or
                damages whatsoever associated with your use, inability to use,
                or your interaction with other users of, the Sedge Protocol,
                including any direct, indirect, incidental, special, exemplary,
                punitive or consequential damages, or loss of profits,
                cryptocurrencies, tokens, or anything else of value.
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          4.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Third-Party Content
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          The Site may contain hyperlinks or references to third party websites
          or content. Any such hyperlinks or references are provided for your
          information and convenience only. We have no control over third party
          websites and accept no legal responsibility for any content, material
          or information contained in them. The display of any hyperlink and
          reference to any third-party website does not mean that we endorse
          that third party’s website, products or services or opine on the
          accuracy or reliability of such information. Your use of a third-party
          site may be governed by the terms and conditions of that third-party
          site.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          5.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Intellectual Property Rights
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          Sedge is the owner of all intellectual property rights in the Site and
          the material published on them. To the extent practical, these works
          are protected by copyright laws and all such rights are reserved.
          www.sedge.fi is the uniform resource locator (``URL``) of Sedge. You
          will not make use of this URL (or any other URL owned by us) on
          another website or digital platform without our prior written consent.
          Any unauthorized use or reproduction may be prosecuted. You will
          retain ownership of all copyright in data you upload or submit by,
          through or to the Site. You grant us a worldwide, royalty-free,
          irrevocable license to use, copy, distribute or publish and send this
          data in any manner.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          6.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Limitation of Liability
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div className="italic">
          Under no circumstances shall we or any of our officers, directors,
          employees, contractors, agents, affiliates, or subsidiaries be liable
          to you for any indirect, punitive, incidental, special, consequential,
          or exemplary damages, including (but not limited to) damages for loss
          of profits, goodwill, use, data, or other intangible property, arising
          out of or relating to any access or use of the Site including the
          user-interface, nor will we be responsible for any damage, loss, or
          injury resulting from hacking, tampering, or other unauthorized access
          or use of the Site including the user-interface or the information
          contained within it.
        </div>
        <div className="italic">
          We assume no liability or responsibility for any: (a) errors,
          mistakes, or inaccuracies of content; (b) personal injury or property
          damage, of any nature whatsoever, resulting from any access or use of
          the Site including the user-interface; (c) unauthorized access or use
          of any secure server or database in our control, or the use of any
          information or data stored therein; (d) interruption or cessation of
          function related to the Site; (e) bugs, viruses, trojan horses, or the
          like that may be transmitted to or through the Site; (f) errors or
          omissions in, or loss or damage incurred as a result of the use of,
          any content made available through the Site; and (g) the defamatory,
          offensive, or illegal conduct of any third party.
        </div>
        <div className="italic">
          Under no circumstances shall we or any of our officers, directors,
          employees, contractors, agents, affiliates, or subsidiaries be liable
          to you for any claims, proceedings, liabilities, obligations, damages,
          losses, or costs in an amount exceeding $100.00. This limitation of
          liability applies regardless of whether the alleged liability is based
          on contract, tort, negligence, strict liability, or any other basis,
          and even if we have been advised of the possibility of such liability.
          Some jurisdictions do not allow the exclusion of certain warranties or
          the limitation or exclusion of certain liabilities, but your
          acceptance of these Terms constitutes an agreement to limit the
          liability of Sedge and our officers, directors, employees,
          contractors, agents, affiliates, or subsidiaries to the maximum extent
          possible under any applicable laws.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          7.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Disclaimers
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          We do not guarantee that the Site will be secure or free from bugs or
          viruses.
        </div>
        <div>
          You are responsible for configuring your information technology,
          computer programs and/or platform in order to access the Site. You
          should use and deploy your own virus protection and security software.
          We cannot promise that the use of the Site, or any content taken from
          the Site, will not infringe the rights of any third party.
        </div>
        <div>
          The content and materials available on the Site are for informational
          purposes only and are not intended to address your particular
          requirements or needs. In particular, the content and materials
          available on the Site do not constitute any form of advice, referral
          or recommendation by us, should not be regarded as an offer,
          solicitation, invitation or recommendation to buy or sell tokens or
          any other financial services and is not intended to be relied upon by
          you in making any specific decision to buy or sell a token. We
          recommend that you seek independent advice from financial, legal and
          tax advisors before making any such decision particularly in light of
          the risks associated with digital assets.
        </div>
        <div>
          Nothing included in the site constitutes an offer or solicitation to
          sell, or distribution of, investments and related services to anyone
          in any jurisdiction.
        </div>
        <div>
          From time to time, reference may be made to data we have gathered.
          These references may be selective or, may be partial. As markets
          change continuously, previously published information and data may not
          be current and should not be relied upon.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          8.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Indemnification
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          You agree to indemnify and hold Sedge and our officers, directors,
          employees, contractors, agents, affiliates, or subsidiaries harmless
          from any claim or demand, including attorneys’ fees and costs, made by
          any third-party due to or arising out of 1) your use of the site or 2)
          this agreement.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          9.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          General
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          We may perform any of our obligations, and exercise any of the rights
          granted to us under these Terms, through an affiliated or unaffiliated
          third-party. We may assign any or all our rights and obligations under
          these Terms to any third-party.
        </div>
        <div>
          If any clause or part of any clause of these Terms is found to be
          void, unenforceable or invalid, then it will be severed from these
          Terms, leaving the remainder in full force and effect, provided that
          the severance has not altered the basic nature of these Terms.
        </div>
        <div>
          No single or partial exercise, or failure or delay in exercising any
          right, power or remedy by us shall constitute a waiver by us of, or
          impair or preclude any further exercise of, that or any right, power
          or remedy arising under these terms and conditions or otherwise. If
          any of the provisions in these Terms are found to be illegal, invalid
          or unenforceable by any court of competent jurisdiction, the remainder
          shall continue in full force and effect.
        </div>
        <div>
          All disclaimers, indemnities and exclusions in these Terms shall
          survive termination of the Terms and shall continue to apply during
          any suspension or any period during which the Site is not available
          for you to use for any reason whatsoever.
        </div>
        <div>
          These Terms and the documents referred to in them set out the entire
          agreement between you and us with respect to your use of the site,
          Sedge and the services provided via the site and supersede any and all
          prior or contemporaneous representations, communications or agreements
          (written or oral) made between you or us.
        </div>
        <div className="italic">
          Any dispute, controversy, or claim arising out of or in relation to
          these Terms, including the validity, invalidity, breach or termination
          thereof, shall be settled by arbitration. There shall be one
          arbitrator; the appointing authority may be based on mutual agreement,
          be chosen by the parties or in the absence of such agreement, the
          court may designate an appointing authority. The seat of the
          arbitration shall be the British Virgin Islands and the language of
          the arbitration shall be English. The applicable law shall be British
          Virgin Islands law or another choice of law determined in Sedge’s sole
          discretion.
        </div>
        <div className="italic">
          With respect to all persons and entities, regardless of whether they
          have obtained or used the site for personal, commercial or other
          purposes, all disputes, controversies or claims must be brought in the
          parties’ individual capacity, and not as a plaintiff or class member
          in any purported class action, collective action or other
          representative proceeding. This waiver applies to class arbitration,
          and, unless we agree otherwise, the arbitrator may not consolidate
          more than one person’s claims. You agree that, by entering into this
          agreement, you and Sedge are each waiving the right to a trial by jury
          or to participate in a class action, collective action, or other
          representative proceeding of any kind.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          10.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Force Majeure
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          There is a risk that transactions effected through the Site may be
          affected by system failures resulting from adverse events, natural
          disasters, pandemics and other emergencies, as well as unforeseen
          significant changes in the external environment. With regards to
          opportunity loss (e.g., loss of opportunity to place a payment
          instruction, resulting in loss of profits which could have been
          obtained) due to occurrences such as emergency situations and force
          majeure events, Sedge is under no obligation to take any corrective
          action or measure and shall under no circumstances be liable for any
          lost profits or other swapping losses.
        </div>
      </div>

      <div className="flex">
        <div className="text-xl text-secondary-foreground font-extrabold">
          11.
        </div>
        <div className="ml-2 text-xl text-secondary-foreground/80 font-medium">
          Contact Us
        </div>
      </div>

      <div className="flex flex-col m-auto gap-4">
        <div>
          Sedge is a company organized in the British Virgin Islands. Please
          contact us if you have any questions about these Terms or other
          topics, by sending an email to legal@sedge.fi.
        </div>
      </div>

      <div className="flex m-auto pt-12">
        <div>
          <Link
            to="/"
            className="flex items-center text-secondary-foreground/50 hover:text-secondary-foreground/30 gap-x-2"
          >
            Return home <ResetIcon className="scale-y-[-1]" />
          </Link>
        </div>
      </div>
      <div className="flex"></div>
    </div>
  );
};
