import { useMemo } from "react";
import { formatUnits } from "viem";
import { useReadContracts } from "wagmi";

import { IOracleAbi } from "@/abi/IOracle";
import { Token } from "@/lib/pool-types";
import { usePools } from "./usePools";
import { useSwapRoute } from "./useSwapRoute";
import { useUsdcToken } from "./useUsdcToken";

export const useOraclePrice = (
  inputToken: Token | null,
  outputToken: Token | null
) => {
  const { oracles } = usePools();
  const { poolRoute } = useSwapRoute(inputToken, outputToken);
  const { usdc } = useUsdcToken();

  // generate wagmi contracts calls
  const contracts = useMemo(
    () =>
      poolRoute.map(
        (p) =>
          ({
            abi: IOracleAbi,
            address: p.baseOracle,
            functionName: "latestAnswer",
          } as const)
      ),
    [poolRoute]
  );

  const { data } = useReadContracts({
    allowFailure: false,
    contracts,
  });

  const oraclePrice = useMemo(() => {
    if (inputToken && outputToken && usdc && data) {
      const oraclePrices = Array.from({ length: poolRoute.length }, (_, i) => {
        const usdcToX = formatUnits(
          data[i],
          oracles[poolRoute[i].quoteOracle].decimals
        );

        // USDC <> X swap
        // i === 1 means 2nd step in route, USDC => EURS, USDC is input
        if (inputToken.id === usdc.id) {
          return (1 / parseFloat(usdcToX)).toString();
        }
        if (outputToken.id === usdc.id) {
          return usdcToX;
        }

        // X <> Y swap, e.g. CADC => EURS has a CADC => USDC => EURS route
        // Assumes poolIds are ordered correctly in the route
        // i === 0 means 1st step in route, CADC => USDC, USDC is output
        if (i === 0) {
          return (1 / parseFloat(usdcToX)).toString();
        }
        throw new Error(
          "Something has gone wrong in the oracle price calculation."
        );
      });

      const price = oraclePrices.reduce(
        (accum, price) => parseFloat(price) * accum,
        1
      );
      return price;
    }
    return null;
  }, [inputToken, outputToken, oracles, poolRoute, usdc, data]);

  return {
    oraclePrice,
  };
};
