import { useState } from "react";
import { useMemo } from "react";

import { Card, CardContent } from "@/components/ui/card";
import { PoolRow } from "@/components/pools-table/pool-row";
import { Accordion } from "@/components/ui/accordion";
import { AccordionItem } from "@radix-ui/react-accordion";
import {
  AccordionContent,
  AccordionTrigger,
} from "@/components/ui/override/accordion";
import { FlipIcon } from "@/components/pools-table/flip-icon";
import { PoolRowDrawer } from "@/components/pools-table/pool-row-drawer";
import {
  AddLiquidityModal,
  RemoveLiquidityModal,
} from "@/components/liquidity-modal";
import { useBalances } from "@/hooks/useBalances";
import { usePools } from "@/hooks/usePools";
import { Pool } from "@/lib/pool-types";
import { cn } from "@/lib/utils";

export default function Pools() {
  const [isAddLiquidityOpen, setIsAddLiquidityOpen] = useState(false);
  const [isRemoveLiquidityOpen, setIsRemoveLiquidityOpen] = useState(false);
  const [poolIdx, setPoolIdx] = useState(-1);

  const { balances } = useBalances();
  const { pools, poolAddrs, tokens } = usePools();

  // Reshape data and map tokens for passing to components
  const poolData = useMemo(() => {
    return poolAddrs.map<Pool>((addr) => {
      const pool = pools[addr];
      const baseToken = tokens[pool.assets[0]];
      const quoteToken = tokens[pool.assets[1]];

      return {
        id: pool.id,
        label: pool.lptLabel,
        baseToken,
        quoteToken,
        lptPrice: pool.lptPrice,
        lptTotal: pool.lptTotal,
        apr: pool.apr,
        reserveBase: pool.reserveBase,
        reserveQuote: pool.reserveQuote,
        reserveUsd: pool.reserveUsd,
        baseOracle: pool.baseOracle,
        quoteOracle: pool.quoteOracle,
        params: pool.params,
      };
    });
  }, [pools, poolAddrs, tokens]);

  const handleOpenAddLiquidityModal = (index: number) => {
    setPoolIdx(index);
    setIsAddLiquidityOpen(true);
  };

  const handleOpenRemoveLiquidityModal = (index: number) => {
    setPoolIdx(index);
    setIsRemoveLiquidityOpen(true);
  };

  const { isActives, hasActive } = useMemo(() => {
    if (balances) {
      const isActives = poolData.map((d) => balances[d.id].dsp > 0);
      return {
        isActives,
        hasActive: isActives.some((d) => d),
      };
    }
    return {
      isActives: [],
      hasActive: false,
    };
  }, [balances, poolData]);

  return (
    <div className="container mx-auto px-2 py-6 max-w-4xl">
      <div className="hidden sm:grid grid-cols-9 py-4 px-6 font-light text-gray-600 dark:text-gray-400">
        <div className="col-span-2 text-center">Pool</div>
        <div className={"col-span-5 text-center justify-center"}>APR / TVL</div>
        <div className="col-span-2 text-center">Actions</div>
      </div>

      <Accordion type="single" collapsible className="w-full space-y-4">
        {poolData.map((d, i) => (
          <Card
            className={cn(
              "bg-white dark:bg-gray-900 rounded",
              isActives[i] &&
                "border-dotted border-primary dark:border-secondary-foreground/50"
            )}
            key={`${d.quoteToken.symbol}-${d.baseToken.symbol}-row`}
          >
            <CardContent className="p-0">
              <AccordionItem
                value={`${d.quoteToken.symbol}-${d.baseToken.symbol}-row`}
                className="border-none"
              >
                <div className="p-4 sm:p-6">
                  <PoolRow
                    id={d.id}
                    label={d.label}
                    baseToken={d.baseToken}
                    quoteToken={d.quoteToken}
                    totalValue={d.reserveUsd}
                    apr={d.apr}
                    hasLpt={isActives[i]}
                    openAddLiquidityModal={() => handleOpenAddLiquidityModal(i)}
                    openRemoveLiquidityModal={() =>
                      handleOpenRemoveLiquidityModal(i)
                    }
                    triggerButton={
                      <AccordionTrigger className="py-0" asChild>
                        <div>
                          <FlipIcon className="h-4 w-4 text-gray-500 dark:text-gray-200" />
                        </div>
                      </AccordionTrigger>
                    }
                  />
                </div>
                <AccordionContent>
                  <div className="border-t border-gray-100 dark:border-gray-800">
                    <PoolRowDrawer
                      baseToken={d.baseToken}
                      quoteToken={d.quoteToken}
                      baseAmount={d.reserveBase}
                      quoteAmount={d.reserveQuote}
                      baseOracle={d.baseOracle}
                      quoteOracle={d.quoteOracle}
                      poolParams={d.params}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </CardContent>
          </Card>
        ))}
      </Accordion>

      {hasActive && (
        <div className="flex justify-center mt-8">
          <div className="flex items-center gap-3 bg-white dark:bg-gray-900 px-4 py-2 rounded-lg shadow-sm">
            <div className="h-6 w-8 rounded border-dotted border-[1px] border-primary dark:border-secondary-foreground/50" />
            <span className="text-sm font-medium">Active Position</span>
          </div>
        </div>
      )}

      {poolIdx > -1 && (
        <AddLiquidityModal
          isOpen={isAddLiquidityOpen}
          onClose={() => setIsAddLiquidityOpen(false)}
          pool={poolData[poolIdx]}
        />
      )}

      {poolIdx > -1 && (
        <RemoveLiquidityModal
          isOpen={isRemoveLiquidityOpen}
          onClose={() => setIsRemoveLiquidityOpen(false)}
          pool={poolData[poolIdx]}
        />
      )}
    </div>
  );
}
