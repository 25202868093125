import { Address } from "viem";

import { PoolDerivative } from "./pool-derivative";
import { PoolDerivatives } from "./pool-derivatives";
import { PoolOracle } from "./pool-oracle";
import { PoolStatus } from "./pool-status";
import { PoolParams, Token } from "@/lib/pool-types";

interface Props {
  baseToken: Token;
  quoteToken: Token;
  baseAmount: number;
  quoteAmount: number;
  baseOracle: Address;
  quoteOracle: Address;
  poolParams: PoolParams;
}

export const PoolRowDrawer: React.FC<Props> = ({
  baseToken,
  quoteToken,
  baseAmount,
  quoteAmount,
  baseOracle,
  quoteOracle,
  poolParams,
}) => {
  return (
    <div className="p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <PoolDerivative side="base" token={baseToken} amount={baseAmount} />
        <PoolDerivative side="quote" token={quoteToken} amount={quoteAmount} />
        <PoolOracle side="base" oracle={baseOracle} />
        <PoolOracle side="quote" oracle={quoteOracle} />
        <span className="lg:col-start-3 lg:row-start-1">
          <PoolDerivatives
            baseToken={baseToken}
            baseAmount={baseAmount}
            quoteToken={quoteToken}
            quoteAmount={quoteAmount}
          />
        </span>
        <span className="lg:col-start-3">
          <PoolStatus
            baseToken={baseToken}
            baseAmount={baseAmount}
            quoteToken={quoteToken}
            quoteAmount={quoteAmount}
            params={poolParams}
          />
        </span>
      </div>
    </div>
  );
};
