import { useState } from "react";
import { zeroAddress } from "viem";

import { Button } from "@/components/ui/button";
import { useLiquidityWithdrawTwoSided } from "@/hooks/useLiquidityWithdraw/use-two-sided";
import { useOraclePrice } from "@/hooks/useOraclePrice";
import { Pool, Token } from "@/lib/pool-types";
import { W3Number } from "@/lib/w3Num";
import { LiquidityTokenInput } from "./liquidity-token-input";
import { RemoveLptStats } from "./remove-lpt-stats";

interface Props {
  pool: Pool;
  closeModal: () => void;
}
export const RemoveField: React.FC<Props> = ({ pool, closeModal }) => {
  const [lptAmount, setLptAmount] = useState<W3Number | null>(null);

  const { oraclePrice } = useOraclePrice(pool.baseToken, pool.quoteToken);
  const { baseTokenAmount, quoteTokenAmount, withdraw } =
    useLiquidityWithdrawTwoSided(pool, lptAmount);

  const lpt: Token = {
    id: pool.id,
    oracle: zeroAddress,
    decimals: 18,
    name: `${pool.label} LPT`,
    symbol: `${pool.label} LPT`,
    icon: "/assets/sedge-lpt.svg",
  };

  const handleWithdraw = async () => {
    await withdraw();
    closeModal();
  };

  return (
    <>
      <LiquidityTokenInput
        direction="withdraw"
        token={lpt}
        inputValue={lptAmount}
        onChange={setLptAmount}
        tokenOptions={[lpt]}
        setToken={() => {}}
      />

      {/* Removed LPT stats */}
      <RemoveLptStats
        pool={pool}
        baseTokenAmount={baseTokenAmount}
        quoteTokenAmount={quoteTokenAmount}
        oraclePrice={oraclePrice}
      />

      {/* Remove Liquidity Button */}
      <Button
        className="w-full dark:bg-blue-600 dark:hover:bg-blue-700 text-lg py-6"
        onClick={handleWithdraw}
      >
        Remove Liquidity
      </Button>
    </>
  );
};
