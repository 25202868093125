import { ChangeEvent, useEffect, useState } from "react";
import { WalletIcon } from "lucide-react";
import { parseUnits } from "viem";

import { cn, formatDecimalString } from "@/lib/utils";
import { toW3Number, W3Number } from "@/lib/w3Num";
import { Skeleton } from "./ui/skeleton";
import { TokenSelectButton } from "./token-select-button";
import { Token } from "@/lib/pool-types";

export type SwapSide = "buy" | "sell";

interface TokenInputProps {
  balance: W3Number | null;
  controlled: boolean;
  openModal: () => void;
  setTokenAmount: (arg: W3Number) => void;
  side: SwapSide;
  token: Token | null;
  tokenAmount: W3Number | null;
}
export const SwapTokenInput = ({
  balance,
  controlled,
  openModal,
  setTokenAmount,
  side,
  token,
  tokenAmount,
}: TokenInputProps) => {
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const tokenDec = token ? token.decimals : 0;
  const bal_ = balance !== null ? balance.big : 0n;

  useEffect(() => {
    if (!tokenAmount) {
      setInputValue("");
    }
  }, [tokenAmount]);

  useEffect(() => {
    const minAmount = parseUnits("0.01", tokenDec);

    if (tokenAmount) {
      if (tokenAmount.big > bal_) {
        setIsError(true);
      } else if (tokenAmount.big === 0n) {
        setIsError(false);
      } else if (tokenAmount.big < minAmount) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    } else {
      setIsError(false);
    }
  }, [bal_, tokenAmount, tokenDec]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      setInputValue("");
      setTokenAmount(toW3Number(0n, 0, 0));
      return;
    }

    // regex matches: ".35", "0.35", "123", "12.3", "123."
    const regex = /^(\d+\.?\d*|\.\d*)$/;
    if (regex.test(value)) {
      // skip additional 0 values when input is already 0
      if (Number(displayValue) === 0 && Number(value) === 0) return;

      setInputValue(value);

      if (value === ".") {
        setTokenAmount(toW3Number(0n, 0, 0));
        return;
      }

      const bigIntValue = parseUnits(value, tokenDec);
      setTokenAmount(toW3Number(bigIntValue, tokenDec, tokenDec));
    } else {
      console.error("Invalid input:", value);
    }
  };

  const handleBalanceClick = () => {
    setTokenAmount(toW3Number(bal_, tokenDec, tokenDec));
    setInputValue(formatDecimalString(bal_, tokenDec, 6));
  };

  const displayValue =
    !controlled && tokenAmount?.hStr ? tokenAmount.hStr : inputValue;

  return (
    <div
      className={cn(
        "flex flex-col w-full justify-between",
        "pt-4 pb-2 pl-4 pr-6",
        "border rounded-md",
        "dark:border-gray-900 dark:bg-gray-800"
      )}
    >
      <div className="flex w-full">
        <div className="flex w-3/4 text-xs text-foreground/60">
          {side === "buy" ? "Buying" : "Selling"}
        </div>
        <div className="flex flex-grow justify-end">
          {/* Balance */}
          <div
            className="text-left hover:cursor-pointer"
            onClick={handleBalanceClick}
            onKeyDown={handleBalanceClick}
            role="button"
            tabIndex={0}
          >
            <span
              className={`text-md sm:text-sm ${
                isError && controlled
                  ? "text-red-900 dark:text-red-500/80"
                  : "text-secondary dark:text-primary/80"
              }`}
            >
              <div className="flex items-center">
                <WalletIcon className="grayscale-[20%]" height={14} />
                {formatDecimalString(bal_, tokenDec, 2)}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div className="flex w-full">
        {/* Icon & Label */}
        <div className="flex items-center gap-3">
          {token ? (
            <TokenSelectButton token={token} openModal={openModal} />
          ) : (
            <>
              <Skeleton className="w-6 h-6 rounded-full" />
              <Skeleton className="w-12 h-6 rounded-sm" />
            </>
          )}
        </div>
        {/* Value input */}
        <div className="flex-1">
          <input
            type="text"
            value={displayValue}
            onChange={handleInputChange}
            onFocus={(e) => {
              // Move cursor to the end on focus
              requestAnimationFrame(() => {
                const length = e.target.value.length;
                e.target.setSelectionRange(length, length);
              });
            }}
            placeholder="0"
            className={cn(
              "w-full bg-transparent h-10 text-right text-md sm:text-md text-foreground focus:outline-none",
              isError
                ? "border-red-500 placeholder-red-700"
                : "placeholder-foreground"
            )}
          />
        </div>
      </div>
      {/* Error */}
      {isError && controlled && (
        <p className="text-red-500 text-xs">
          {tokenAmount && tokenAmount.big > bal_
            ? "Insufficient balance"
            : tokenAmount && tokenAmount.big > 0n
            ? `Minimum amount is 0.01 ${token}`
            : ""}
        </p>
      )}
    </div>
  );
};
