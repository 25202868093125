import { CaretDownIcon } from "@radix-ui/react-icons";

import { TokenIcon } from "./token-icon";
import { Button } from "./ui/override/button";
import { Token } from "@/lib/pool-types";

interface Props {
  token: Token;
  openModal: () => void;
}

export const TokenSelectButton: React.FC<Props> = ({ token, openModal }) => {
  return (
    <Button
      variant="ghost"
      className="p-2 -mx-2 flex gap-x-1"
      onClick={openModal}
    >
      <TokenIcon symbol={token.symbol} classNames="w-6 h-6" />
      <span className="font-semibold sm:text-base text-foreground">
        {token.symbol}
      </span>
      <CaretDownIcon />
    </Button>
  );
};
