// Fetch all available images in icons folder at start
const iconImgs: Record<string, { default: string }> = import.meta.glob(
  "/src/icons/*.{jpg,png,svg}",
  { eager: true }
);

// Returns icon resolved to /{path}/{baseFn}.[jpg|png|svg]
export const getIconSrc = (baseFn: string) => {
  for (const [path, module] of Object.entries(iconImgs)) {
    if (path.toLowerCase().includes(`/${baseFn.toLowerCase()}.`)) {
      return module.default;
    }
  }
  throw new Error(`Could not locate ${baseFn} icon`);
};
