import { ModeToggle } from "./mode-toggle";
import { LatestBlock } from "./latest-block";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
  return (
    <div className="flex-col">
      {/* Link Icons */}
      <div className="flex flex-col py-8">
        <div className="flex mt-8 mb-4 justify-center items-center gap-4">
          <div className="opacity-50">
            <LatestBlock />
          </div>
          <ModeToggle />

          <div className="opacity-50 text-xs">rev:{__GIT_HASH__}</div>
        </div>
        <div className="flex justify-center items-center">
          <Link
            to="/terms-of-use"
            className="text-xs opacity-40 hover:underline"
          >
            Terms of Use
          </Link>
        </div>
        <div className="flex justify-center items-center text-xs opacity-40">
          <div>© {new Date().getFullYear()}, Sedge.fi</div>
        </div>
      </div>
    </div>
  );
};
