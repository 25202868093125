import { X } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { DialogContent } from "@/components/ui/override/dialog";
import { Token } from "@/lib/pool-types";
import { useBalances } from "@/hooks/useBalances";
import { usePools } from "@/hooks/usePools";
import { TokenIcon } from "../token-icon";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  setToken: (token: Token) => void;
}
export const SelectTokenModal: React.FC<Props> = ({
  isOpen,
  onClose,
  setToken,
}) => {
  const { tokens } = usePools();
  const { balances } = useBalances();

  // sort tokens by symbol
  const sortedTokens = Object.values(tokens).sort((a, b) =>
    a.symbol.localeCompare(b.symbol)
  );

  const handleSetToken = (token: Token) => {
    setToken(token);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="dark:bg-gray-900 dark:border-gray-800 border max-w-md top-[-20%]">
        <DialogHeader className="border-b border-gray-800 pb-4">
          <div className="flex items-center justify-between">
            <DialogTitle className="text-lg font-normal">
              Select Token
            </DialogTitle>
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <X className="h-5 w-5" />
            </Button>
          </div>
        </DialogHeader>

        <div>
          {sortedTokens.map((token) => (
            <Button
              key={token.id}
              className="flex w-full pl-1 rounded-sm justify-start"
              onClick={() => handleSetToken(token)}
              variant="ghost"
            >
              <div className="flex w-1/2 items-center gap-x-2">
                <TokenIcon symbol={token.symbol} classNames="w-5 h-5" />
                {token.symbol}
              </div>
              <div className="flex w-1/2 justify-end font-light text-secondary dark:text-secondary-foreground">
                {balances ? balances[token.id].dsp : "--"}
              </div>
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
