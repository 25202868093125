import { useEffect, useState } from "react";
import { CogIcon } from "lucide-react";
import { usePrivy } from "@privy-io/react-auth";
import { UpdateIcon } from "@radix-ui/react-icons";
import { useAccount } from "wagmi";

import { SwapInfo } from "@/components/swap-info";
import { SwapTokenInput } from "@/components/swap-token-input";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/override/button";
import { EditTxSettingsModal } from "@/components/tx-settings-modal";
import { useBalances } from "@/hooks/useBalances";
import { useOraclePrice } from "@/hooks/useOraclePrice";
import { usePools } from "@/hooks/usePools";
import { useSwap } from "@/hooks/useSwap";
import { useTheme } from "@/hooks/useTheme";
import { DEFAULT_POOL, SwapField } from "@/lib/constants";
import { Token } from "@/lib/pool-types";
import { cn } from "@/lib/utils";
import { toW3Number, W3Number } from "@/lib/w3Num";
import { SelectTokenModal } from "@/components/select-token-modal";

export default function Swaps() {
  const [inToken, setInToken] = useState<Token | null>(null);
  const [outToken, setOutToken] = useState<Token | null>(null);
  const [inAmount, setInAmount] = useState<W3Number | null>(null);
  const [outAmount, setOutAmount] = useState<W3Number | null>(null);
  const [inBal, setInBal] = useState<W3Number | null>(null);
  const [outBal, setOutBal] = useState<W3Number | null>(null);
  const [exactToken, setExactToken] = useState<SwapField | null>(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isTxSettingsOpen, setIsTxSettingsOpen] = useState(false);
  const [isSelectInTokenOpen, setIsSelectInTokenOpen] = useState(false);
  const [isSelectOutTokenOpen, setIsSelectOutTokenOpen] = useState(false);

  const { theme } = useTheme();
  const { connectWallet } = usePrivy();
  const { address: connectedAddress } = useAccount();
  const { pools, poolAddrs, tokens } = usePools();
  const { balances, refetch: refetchBal } = useBalances();
  const {
    swap,
    estimatedInAmount,
    estimatedOutAmount,
    minInAmount,
    minOutAmount,
    spotPrice,
    swapError,
  } = useSwap(
    inToken,
    outToken,
    inAmount ?? toW3Number(0n, 18, 6), // default 0e18
    outAmount ?? toW3Number(0n, 18, 6), // default 0e18
    exactToken === null ? SwapField.InToken : exactToken
  );
  // fetch current oracle price
  const { oraclePrice } = useOraclePrice(inToken, outToken);

  // Set default assets on token list load
  useEffect(() => {
    if (pools && tokens && poolAddrs.length) {
      if (!inToken && !outToken && DEFAULT_POOL in pools) {
        const defaultFromAddr = pools[DEFAULT_POOL].assets[1]; // USDC
        const defaultToAddr = pools[DEFAULT_POOL].assets[0]; // CADC
        setInToken(tokens[defaultFromAddr]);
        setOutToken(tokens[defaultToAddr]);
      }
    }
  }, [pools, poolAddrs, tokens, inToken, outToken]);

  // Set user balances for selected token
  useEffect(() => {
    if (balances && inToken && outToken) {
      setInBal(balances[inToken.id]);
      setOutBal(balances[outToken.id]);
    }
  }, [balances, inToken, outToken]);

  const handleChangeInToken = (token: Token) => {
    if (token.symbol === "USDC") {
      setOutToken(inToken);
    } else if (inToken && inToken.symbol === "USDC") {
      setOutToken(inToken);
    }
    setInToken(token);
  };

  const handleChangeOutToken = (token: Token) => {
    if (token.symbol === "USDC") {
      setInToken(outToken);
    } else if (outToken && outToken.symbol === "USDC") {
      setInToken(outToken);
    }
    setOutToken(token);
  };

  const handleSyncInOutValueChange = (
    editedField: SwapField,
    value: W3Number
  ) => {
    setExactToken(editedField);

    if (editedField == SwapField.InToken) {
      setInAmount(value);
    } else {
      setOutAmount(value);
    }

    setIsEdited(true);
  };

  // Flip swap direction
  const handleSwitchInputOutput = () => {
    if (exactToken === SwapField.InToken) {
      setInAmount(estimatedOutAmount);
      setOutAmount(inAmount);
      setExactToken(SwapField.OutToken);
    } else if (exactToken === SwapField.OutToken) {
      setInAmount(outAmount);
      setOutAmount(estimatedInAmount);
      setExactToken(SwapField.InToken);
    }
    const oldInToken = inToken;
    setInToken(outToken);
    setOutToken(oldInToken);

    setIsEdited(false);
  };

  // Perform swap
  const handleSwap = async () => {
    if (inToken && outToken && inAmount) {
      await swap(inToken, outToken, inAmount);

      setInAmount(null);
      refetchBal();
    }
  };

  return (
    <>
      <div className="w-full max-w-lg mx-auto pt-[5%] relative">
        <Card
          className={cn(
            "flex flex-col gap-2",
            "p-8",
            "border shadow-md rounded",
            "bg-white dark:bg-gray-900"
          )}
        >
          <div className="flex w-full justify-end text-gray-400">
            <Button
              aria-label="Transaction settings"
              variant="ghost"
              onClick={() => setIsTxSettingsOpen(true)}
            >
              <CogIcon width={18} />
            </Button>
          </div>
          <div className="relative">
            <SwapTokenInput
              balance={inBal}
              controlled={isEdited && exactToken === SwapField.InToken}
              openModal={() => setIsSelectInTokenOpen(true)}
              setTokenAmount={(amount) =>
                handleSyncInOutValueChange(SwapField.InToken, amount)
              }
              side="sell"
              token={inToken}
              tokenAmount={
                exactToken === SwapField.InToken ? inAmount : estimatedInAmount
              }
            />

            <div className="absolute w-full -bottom-4">
              <Button
                aria-label="Change swap direction"
                variant="ghost"
                className={cn(
                  "flex m-auto h-8 w-8 p-0 rounded-full",
                  "bg-primary hover:brightness-105 hover:bg-primary",
                  "items-center justify-cente"
                )}
                onClick={handleSwitchInputOutput}
              >
                <div className="text-white">
                  <UpdateIcon height={18} width={18} />
                </div>
              </Button>
            </div>
          </div>
          <SwapTokenInput
            balance={outBal}
            controlled={isEdited && exactToken === SwapField.OutToken}
            openModal={() => setIsSelectOutTokenOpen(true)}
            setTokenAmount={(amount) =>
              handleSyncInOutValueChange(SwapField.OutToken, amount)
            }
            side="buy"
            token={outToken}
            tokenAmount={
              exactToken === SwapField.OutToken ? outAmount : estimatedOutAmount
            }
          />
          <div className="flex w-full pb-2 text-xs gap-x-1 items-center justify-end align-middle">
            Powered by
            <img
              aria-label="DFX logo"
              alt="DFX"
              src={
                theme === "light"
                  ? "/assets/dfx-logo-light.svg"
                  : "/assets/dfx-logo-dark.svg"
              }
              className="w-20"
            />
          </div>
          {connectedAddress ? (
            <Button onClick={handleSwap}>Swap</Button>
          ) : (
            <Button onClick={connectWallet}>Connect</Button>
          )}
          {inToken &&
            outToken &&
            !(!inAmount && !outAmount) &&
            !(
              Number(inAmount?.dsp ?? 0) === 0 &&
              Number(outAmount?.dsp ?? 0) === 0
            ) && (
              <SwapInfo
                inToken={inToken}
                inAmount={
                  exactToken == SwapField.InToken ? minInAmount : minOutAmount
                }
                outToken={outToken}
                outAmount={
                  exactToken === SwapField.InToken ? minOutAmount : minInAmount
                }
                oraclePrice={oraclePrice}
                spotPrice={spotPrice}
              />
            )}
        </Card>
        {swapError && (
          <div className="absolute bottom pt-4 text-xs text-warning">
            {swapError}
          </div>
        )}
      </div>
      <EditTxSettingsModal
        isOpen={isTxSettingsOpen}
        onClose={() => setIsTxSettingsOpen(false)}
      />
      <SelectTokenModal
        isOpen={isSelectInTokenOpen}
        onClose={() => setIsSelectInTokenOpen(false)}
        setToken={handleChangeInToken}
      />
      <SelectTokenModal
        isOpen={isSelectOutTokenOpen}
        onClose={() => setIsSelectOutTokenOpen(false)}
        setToken={handleChangeOutToken}
      />
    </>
  );
}
